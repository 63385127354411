import React, { useEffect, useState, useContext, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import useSettings from "../../hooks/useSettings";
import { toast } from 'react-toastify';
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import OnlyForSuperUser from "../OnlyForSuperUser";
import useAuth from "../../hooks/useAuth.js";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Colorize, AttachFile, Delete } from "@material-ui/icons";
import ColorPicker from "../ColorPicker";
import ColorModeContext from "../../layout/themeContext";
import api from "../../services/api";
import { getBackendUrl } from "../../config";
import defaultLogoLight from "../../assets/logo.png";
import defaultLogoDark from "../../assets/logo-black.png";
import defaultLogoFavicon from "../../assets/favicon.ico";
import defaultEnterImage from "../../assets/loginImage.jpeg";
import defaultRegisterImage from "../../assets/loginImage.jpeg";
import ColorBoxModal from "../ColorBoxModal/index.js";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: "#128c7e"
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  uploadInput: {
    display: "none",
  },
  appLogoLightPreviewDiv: {
    backgroundColor: "white",
    padding: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#424242",
    textAlign: "center",
  },
  appLogoDarkPreviewDiv: {
    backgroundColor: "#424242",
    padding: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "white",
    textAlign: "center",
  },
  appLogoFaviconPreviewDiv: {
    padding: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "black",
    textAlign: "center",
  },
  appLogoLightPreviewImg: {
    width: "auto",
    height: "72px",
    maxWidth: "100%",
  },
  appLogoDarkPreviewImg: {
    width: "auto",
    height: "72px",
    maxWidth: "100%",
  },
  appLogoFaviconPreviewImg: {
    width: "auto",
    height: "72px",
    maxWidth: "100%",
  },
  loginEnterImageDiv: {
    backgroundColor: theme.mode === "light" ? "#FFF" : "#333",
    padding: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.mode === "light" ? "#333" : "#FFF",
    textAlign: "center",
    height: "380px",
  },
  loginRegisterImageDiv: {
    backgroundColor: theme.mode === "light" ? "#FFF" : "#333",
    padding: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.mode === "light" ? "#333" : "#FFF",
    textAlign: "center",
    height: "380px",
  },
  loginEnterImageImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  loginRegisterImageImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  }
}));

export default function Whitelabel(props) {
  const { settings } = props;
  const classes = useStyles();
  const [settingsLoaded, setSettingsLoaded] = useState({});

  const { getCurrentUserInfo } = useAuth();
  const [currentUser, setCurrentUser] = useState({});

  const { colorMode } = useContext(ColorModeContext);
  const [primaryColorLightModalOpen, setPrimaryColorLightModalOpen] = useState(false);
  const [primaryColorDarkModalOpen, setPrimaryColorDarkModalOpen] = useState(false);

  const logoLightInput = useRef(null);
  const logoDarkInput = useRef(null);
  const logoFaviconInput = useRef(null);
  const appNameInput = useRef(null);
  const loginEnterImageInput = useRef(null);
  const loginRegisterImageInput = useRef(null);
  const [loginEnterImage, setloginEnterImage] = useState(defaultEnterImage);
  const [loginRegisterImage, setloginRegisterImage] = useState(defaultRegisterImage);
  const [appName, setAppName] = useState(settingsLoaded.appName || "");

  const { update } = useSettings();

  function updateSettingsLoaded(key, value) {
    if (key === 'primaryColorLight' || key === 'primaryColorDark' || key === 'appName') {
      localStorage.setItem(key, value);
    };
    const newSettings = { ...settingsLoaded };
    newSettings[key] = value;
    setSettingsLoaded(newSettings);
  }

  useEffect(() => {
    getCurrentUserInfo().then(
      (u) => {
        setCurrentUser(u);
      }
    );

    if (Array.isArray(settings) && settings.length) {
      const primaryColorLight = settings.find((s) => s.key === "primaryColorLight")?.value;
      const primaryColorDark = settings.find((s) => s.key === "primaryColorDark")?.value;
      const appLogoLight = settings.find((s) => s.key === "appLogoLight")?.value;
      const appLogoDark = settings.find((s) => s.key === "appLogoDark")?.value;
      const appLogoFavicon = settings.find((s) => s.key === "appLogoFavicon")?.value;
      const appName = settings.find((s) => s.key === "appName")?.value;
      const loginEnterImage = settings.find((s) => s.key === "loginEnterImage")?.value;
      const loginRegisterImage = settings.find((s) => s.key === "loginRegisterImage")?.value;

      setAppName(appName || "");
      setSettingsLoaded({ primaryColorLight, primaryColorDark, appLogoLight, appLogoDark, appLogoFavicon, appName, loginEnterImage, loginRegisterImage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  async function handleSaveSetting(key, value) {
    await update({
      key,
      value,
    });
    updateSettingsLoaded(key, value);
    toast.success("Operação atualizada com sucesso.");
  }

  const uploadLogo = async (e, mode) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("typeArch", "logo");
    formData.append("mode", mode);
    formData.append("file", file);

    await api.post("/settings-whitelabel/logo", formData, {
      onUploadProgress: (event) => {
        let progress = Math.round(
          (event.loaded * 100) / event.total
        );
        console.log(
          `A imagem  está ${progress}% carregada... `
        );
      },
    }).then((response) => {
      updateSettingsLoaded(`appLogo${mode}`, response.data);
      colorMode[`setAppLogo${mode}`](getBackendUrl() + "/public/" + response.data);
    }).catch((err) => {
      console.error(`Houve um problema ao realizar o upload da imagem.`);
      console.log(err);
    });
  };

  const handleUploadloginImage = async (e, mode) => {
    if (!e.target.files) {
      return;
    }
  
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("typeArch", "logo");
    formData.append("mode", mode);
    formData.append("file", file);
  
    await api.post("/settings-whitelabel/login", formData)
      .then((response) => {
        updateSettingsLoaded(`login${mode}Image`, response.data);
        colorMode[`setlogin${mode}Image`](getBackendUrl() + "/public/" + response.data);
      })
      .catch((err) => {
        console.error(`There was a problem uploading the image.`);
        console.log(err);
      });
  };

  return (
    <>
      <Grid spacing={3} container>
        <OnlyForSuperUser
          user={currentUser}
          yes={() => (
            <>
              <Grid xs={12} sm={6} md={4} item>
                <FormControl className={classes.selectContainer}>
                  <TextField
                    id="primary-color-light-field"
                    label="Cor Primária Modo Claro"
                    variant="standard"
                    value={settingsLoaded.primaryColorLight || ""}
                    onClick={() => setPrimaryColorLightModalOpen(true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div
                            style={{ backgroundColor: settingsLoaded.primaryColorLight }}
                            className={classes.colorAdorment}
                          ></div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton
                          size="small"
                          color="default"
                          onClick={() => setPrimaryColorLightModalOpen(true)}
                        >
                          <Colorize />
                        </IconButton>
                      ),
                    }}
                  />
                </FormControl>
                <ColorBoxModal
                  open={primaryColorLightModalOpen}
                  handleClose={() => setPrimaryColorLightModalOpen(false)}
                  onChange={(color) => {
                    handleSaveSetting("primaryColorLight", `#${color.hex}`);
                    colorMode.setPrimaryColorLight(`#${color.hex}`);
                  }}
                  currentColor={settingsLoaded.primaryColorLight}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormControl className={classes.selectContainer}>
                  <TextField
                    id="primary-color-dark-field"
                    label="Cor Primária Modo Escuro"
                    variant="standard"
                    value={settingsLoaded.primaryColorDark || ""}
                    onClick={() => setPrimaryColorDarkModalOpen(true)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div
                            style={{ backgroundColor: settingsLoaded.primaryColorDark }}
                            className={classes.colorAdorment}
                          ></div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton
                          size="small"
                          color="default"
                          onClick={() => setPrimaryColorDarkModalOpen(true)}
                        >
                          <Colorize />
                        </IconButton>
                      ),
                    }}
                  />
                </FormControl>
                <ColorBoxModal
                  open={primaryColorDarkModalOpen}
                  handleClose={() => setPrimaryColorDarkModalOpen(false)}
                  onChange={(color) => {
                    handleSaveSetting("primaryColorDark", `#${color.hex}`);
                    colorMode.setPrimaryColorDark(`#${color.hex}`);
                  }}
                  currentColor={settingsLoaded.primaryColorDark}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormControl className={classes.selectContainer}>
                  <TextField
                    id="appname-field"
                    label="Nome do sistema"
                    variant="standard"
                    name="appName"
                    value={appName}
                    inputRef={appNameInput}
                    onChange={(e) => {
                      setAppName(e.target.value);
                    }}
                    onBlur={async (_) => {
                      await handleSaveSetting("appName", appName);
                      colorMode.setAppName(appName || "O Plano");
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormControl className={classes.selectContainer}>
                  <TextField
                    id="logo-light-upload-field"
                    label="Logotipo claro"
                    variant="standard"
                    value={settingsLoaded.appLogoLight || ""}
                    InputProps={{
                      endAdornment: (
                        <>
                          {settingsLoaded.appLogoLight && (
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                handleSaveSetting("appLogoLight", "");
                                colorMode.setAppLogoLight(defaultLogoLight);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          )}
                          <input
                            type="file"
                            id="upload-logo-light-button"
                            ref={logoLightInput}
                            className={classes.uploadInput}
                            onChange={(e) => uploadLogo(e, "Light")}
                          />
                          <label htmlFor="upload-logo-light-button">
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                logoLightInput.current.click();
                              }}
                            >
                              <AttachFile />
                            </IconButton>
                          </label>
                        </>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormControl className={classes.selectContainer}>
                  <TextField
                    id="logo-dark-upload-field"
                    label="Logotipo escuro"
                    variant="standard"
                    value={settingsLoaded.appLogoDark || ""}
                    InputProps={{
                      endAdornment: (
                        <>
                          {settingsLoaded.appLogoDark && (
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                handleSaveSetting("appLogoDark", "");
                                colorMode.setAppLogoDark(defaultLogoDark);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          )}
                          <input
                            type="file"
                            id="upload-logo-dark-button"
                            ref={logoDarkInput}
                            className={classes.uploadInput}
                            onChange={(e) => uploadLogo(e, "Dark")}
                          />
                          <label htmlFor="upload-logo-dark-button">
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                logoDarkInput.current.click();
                              }}
                            >
                              <AttachFile />
                            </IconButton>
                          </label>
                        </>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormControl className={classes.selectContainer}>
                  <TextField
                    id="logo-favicon-upload-field"
                    label="Favicon"
                    variant="standard"
                    value={settingsLoaded.appLogoFavicon || ""}
                    InputProps={{
                      endAdornment: (
                        <>
                          {settingsLoaded.appLogoFavicon && (
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                handleSaveSetting("appLogoFavicon", "");
                                colorMode.setAppLogoFavicon(defaultLogoFavicon);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          )}
                          <input
                            type="file"
                            id="upload-logo-favicon-button"
                            ref={logoFaviconInput}
                            className={classes.uploadInput}
                            onChange={(e) => uploadLogo(e, "Favicon")}
                          />
                          <label htmlFor="upload-logo-favicon-button">
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                logoFaviconInput.current.click();
                              }}
                            >
                              <AttachFile />
                            </IconButton>
                          </label>
                        </>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <div className={classes.appLogoLightPreviewDiv}>
                  <img className={classes.appLogoLightPreviewImg} src={settingsLoaded.appLogoLight ? `${getBackendUrl()}/public/${settingsLoaded.appLogoLight}` : defaultLogoLight} alt="light-logo-preview" />
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <div className={classes.appLogoDarkPreviewDiv}>
                  <img className={classes.appLogoDarkPreviewImg} src={settingsLoaded.appLogoDark ? `${getBackendUrl()}/public/${settingsLoaded.appLogoDark}` : defaultLogoDark} alt="dark-logo-preview" />
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <div className={classes.appLogoFaviconPreviewDiv}>
                  <img className={classes.appLogoFaviconPreviewImg} src={settingsLoaded.appLogoFavicon ? `${getBackendUrl()}/public/${settingsLoaded.appLogoFavicon}` : defaultLogoFavicon} alt="favicon-preview" />
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6} item>
                <FormControl className={classes.selectContainer}>
                  <TextField
                    id="login-enter-image-upload-field"
                    label="Imagem de Fundo do Login"
                    variant="standard"
                    value={settingsLoaded.loginEnterImage || ""}
                    InputProps={{
                      endAdornment: (
                        <>
                          {settingsLoaded.loginEnterImage && (
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                handleSaveSetting("loginEnterImage", "");
                                colorMode.setloginEnterImage(defaultEnterImage);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          )}
                          <input
                            type="file"
                            id="upload-login-enter-image-button"
                            ref={loginEnterImageInput}
                            className={classes.uploadInput}
                            onChange={(e) => handleUploadloginImage(e, "Enter")}
                          />
                          <label htmlFor="upload-login-enter-image-button">
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                loginEnterImageInput.current.click();
                              }}
                            >
                              <AttachFile />
                            </IconButton>
                          </label>
                        </>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={6} item>
                <FormControl className={classes.selectContainer}>
                  <TextField
                    id="login-register-image-upload-field"
                    label="Imagem de Fundo do Signup"
                    variant="standard"
                    value={settingsLoaded.loginRegisterImage || ""}
                    InputProps={{
                      endAdornment: (
                        <>
                          {settingsLoaded.loginRegisterImage && (
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                handleSaveSetting("loginRegisterImage", "");
                                colorMode.setloginRegisterImage(defaultRegisterImage);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          )}
                          <input
                            type="file"
                            id="upload-login-register-image-button"
                            ref={loginRegisterImageInput}
                            className={classes.uploadInput}
                            onChange={(e) => handleUploadloginImage(e, "Register")}
                          />
                          <label htmlFor="upload-login-register-image-button">
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => {
                                loginRegisterImageInput.current.click();
                              }}
                            >
                              <AttachFile />
                            </IconButton>
                          </label>
                        </>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={6} item>
                <div className={classes.loginEnterImageDiv}>
                  <img className={classes.loginEnterImageImg} src={settingsLoaded.loginEnterImage ? `${getBackendUrl()}/public/${settingsLoaded.loginEnterImage}` : defaultEnterImage} alt="login-enter-preview" />
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={6} item>
                <div className={classes.loginRegisterImageDiv}>
                  <img className={classes.loginRegisterImageImg} src={settingsLoaded.loginRegisterImage ? `${getBackendUrl()}/public/${settingsLoaded.loginRegisterImage}` : defaultRegisterImage} alt="login-register-preview" />
                </div>
              </Grid>
            </>
          )}
        />
      </Grid>
    </>
  );
}
